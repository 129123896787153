'use strict';

(function() {
  function ImageResource($resource) {
    return $resource('api/v1/images/:id/:controller/:page/:limit/:sortBy/:order', {
      id: '@_id'
    }, {
      update: {
        method: 'PUT'
      },
      signing: {
        method: 'POST',
        params: {
          id: 'signing'
        }
      },
      signingResource: {
        method: 'POST',
        params: {
          id: 'signing-resource'
        }
      },
      contactResource: {
        method: 'POST',
        params: {
          id: 'contact-resource'
        }
      }
    });
  }

  angular.module('windmanagerApp')
    .factory('Image', ImageResource);

})();
